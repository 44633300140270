.competitor-details {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 50px;
    gap: 50px;

}

.left-card {
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: 800px;
}

.competitor-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.label-name {
    font-size: 18px;
    font-weight: bold;
}

.competitor-value {
    font-size: 18px;
}

.competitor-row {
    display: flex;
    gap: 10px;
    font-size: 18px;
}

.right-card {
    display: flex;
    flex-direction: column;
    gap: 50px;
    max-width: 800px;
}

.update-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    width: 150px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  
  .update-button:hover {
    background-color: #3e8e41;
    /* Darken background on hover */
  }