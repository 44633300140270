.table-container {
  width: 99%;
  margin: 0 auto;
}

.table {
  border-collapse: collapse;
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
  color: #555;
}

.table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.highlight-column:hover {
  cursor: pointer;
  /* background-color: #0146b2; */
}


.delete-button {
  background-color: #f81643;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.react-select__dropdown-indicator {
  display: none;
}

.react-select__control {
  width: 200px;
}

.date-range-picker {
  margin-left: 20px;
}

.date-range-picker__inputs-wrapper {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.date-range-picker__label {
  flex: 1;
  font-size: 14px;
}

.date-range-picker__input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
}

.date-range-picker__selected-date-range {
  font-size: 14px;
}

/* .date-button {
  background-color: #f5515c;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;

} */

.date-button:hover {
  background-color: #f81d71;
}

.button {
  display: inline-flex;
  align-items: center;
  /* Align icon and text */
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin: 5px;
  /* Margin between buttons */
}

.button.date-button {
  background-color: white;
  color: #f81643;
  border: 2px solid #f81643;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
}

.button.date-button:hover {
  background-color: #f81643;
  color: white;
}

.button.clear-filter {
  background-color: #f81643;
  color: #fff;
}

.button.print {
  background-color: white;
  color: #f81643;
  border: 2px solid #f81643;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px 10px;
}

.button.print:hover {
  background-color: #f81643;
  color: white;
}

.button-container {
  text-align: right;
  margin-right: 20px;
}

.button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.button:active {
  transform: translateY(1px);
  /* Pressed effect */
}