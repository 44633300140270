.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: #fff;
    height: 80px;
    padding: 0 20px;
  }
  
  .navbar img {
    height: 70px;
    width: 120px;
    margin-right: 10px;
  }
  
  .navbar ul {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
  }
  
  .navbar li {
    margin: 0 10px;
  }
  
  .navbar a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    transition: color 0.2s ease-in-out;
  }
  
  .navbar a:hover {
    color: #ccc;
  }
  